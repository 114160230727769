import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import PageWithMenu from '../../../spxbasecode/cmps/PageWithMenu.jsx';
import EduMagPrintSSCCCmp from '../cmp/EduMagPrintSSCCCmp.jsx';
import Translate from '../../../Translate.jsx';

class EduMagPrintedSSCCPage extends Component {
  constructor() {
    super();
    let this2 = this;
    this.tag = "EduMagPrintedSSCCPage";
    this.module = "EduMag";
    let pp=window.App.pageParams;
    
    window.App.API.exec("sscc","printedLabel",{sscc:pp.sscc,productId:pp.productId},(ss)=>{
      //let dd=ss.data;
      //this2.setState({ sscc: dd.id });
      window.App.logout();
      
   },(ee)=>{
    console.log("XxxxXX3eeee",ee);
   });
  
  }
  back(){
    window.App.goBack();
  }
  render() {
    let TT = Translate.getT(window.App.lang, this.module, this.tag);
    return (
      <PageWithMenu style={{backgroundColor:"white",height:"100%"}}>      
      <Container className="my-4" style={{backgroundColor:"white",height:"100%"}}>
      IS PRINT
      </Container>
    </PageWithMenu>  
    );
  }
}

export default EduMagPrintedSSCCPage;