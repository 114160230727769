import React, { Component } from 'react';
import { Form, Button, Alert } from 'reactstrap';
import Tools from '../Tools.jsx';
class BaseForm extends Component {
    constructor(props){
        super();
        this.props=props;
        this.module=props.module;
        this.act=props.act;
        this.objs={};
        let this2=this;
        setTimeout(()=>{
          this2.props.parent.form=this2;
          this2.props.parent.initForm();
        },240);
        this.state = {
          components: [],
        };

    }
    init(data){
      
      for (let k in data){
        if (Tools.empty(this.objs[k])){
          continue;
        }
        
        this.objs[k].setValue(data[k]);
      }
      
    }
    async valid(){
      let isValid=true;
      for (let k in this.objs){
        let el=this.objs[k];
        let v= await el.valid();
        isValid=isValid&&v;
      }
      return isValid;
    }
    addObj(obj){
      this.objs[obj.states.name]=obj;
    }
    async getData(){
      let res={};
      for (let k in this.objs){
        let el=this.objs[k];
        let v=el.getValue();
        res[k]=v;
      }
      return res;
    }
    async handle2(){
      let x=await this.valid();
      if (!x){
        return;
      }
      let params= await this.getData();
      let this2=this;
      window.App.API.exec(this.module,this.act,params,(ss)=>{
        //console.log("XxxxkkkkkkXX",ss);
        let params=[];
        this2.props.onSucc(ss.data);
        
     },(ee)=>{
      //if ()
      console.log("XxxiiiixXX3eeee",ee);
     });
    }
    handleSubmit = event => {
      event.preventDefault();
      this.handle2();
    }
    
  
  render() { 
    let error=null;   
    let lab1=this.props.lab1;
    if (Tools.empty(lab1)){
      lab1="Login";
    }
    return (
      <Form onSubmit={this.handleSubmit}>
      {error && <Alert color="danger">{error}</Alert>}
      {this.props.children}
      <Button color="primary" type="submit">{lab1}</Button>
    </Form>
    );
  }
}

export default BaseForm;