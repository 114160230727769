import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import PageWithMenu from '../../../spxbasecode/cmps/PageWithMenu.jsx';
import SSOLoginForm from '../cmp/SSOLoginForm.jsx';
class SSOLoginPage extends Component {
  constructor(){
    super();
    this.tag="SSOLoginPage";
    this.module="SSO";
  }
  clickButton(pageName){
    this.openPage(pageName);
  }
  initForm(){
    
  }
    openPage(pageName){
        window.App.changePage("EduMag",pageName,null);
    }
  render() {    
    return (
     <PageWithMenu showDummy="false">      
      <Container className="my-4">
     <SSOLoginForm></SSOLoginForm>
      </Container>
    </PageWithMenu>
    )
  }
}

export default SSOLoginPage;