import React, { Component } from 'react';
import Menu from './Menu.jsx';
import Tools from '../Tools.jsx';
class PageWithMenu extends Component {
    constructor(props){
        super();
        this.props=props;
        this.isMenuShow=false;
        this.isDummy=Tools.isTrue(Tools.LSGet("isDummy"))&&Tools.isTrue(props.showDummy);
    }
    render(){
      if (this.isDummy){
        return this.renderDummy();
      }else{
        return this.renderNormal();
      }
    }
    renderDummy() { 
      let label1=Tools.LSGet("firstName")+" "+Tools.LSGet("lastName");
      
      return (
        <div style={{backgroundColor:"white"}}>
          <div style={{backgroundColor:"lightgrey",height:"10%",color:"green",fontWeight:"bold"}}>
                Witaj {label1}!
          </div>
          <div className={this.props.className} style={{height:"85%"}}>
            <div className="row g-0">
              <div className='col-sm-1 col-md-2 col-lg-3'></div>
                <div className='col-12 col-sm-10 col-md-8  col-lg-6' style={{ paddingLeft:"5px",paddingLeft:"5px" }}>
                {this.props.children}
               </div>
               <div className='col-sm-1 col-md-2  col-lg-3'></div>
              </div>
            </div>
          </div>
      );
    }
  renderNormal() { 
    let cc=this.props.className;
    cc=!Tools.empty(cc)?(cc+" "):"";
    cc+=" row g-0";
    console.log("ccccc",cc);
    return (
      <div style={{width:"100%",height:"100%"}}>
        <Menu isShow={this.isMenuShow}></Menu>
        <div className={cc} style={{height:"90%"}}>  
        <div className="row g-0">    
          <div className='col-sm-1 col-md-2 col-lg-3'></div>
          <div className='col-12 col-sm-10 col-md-8  col-lg-6' style={{ paddingLeft:"5px",paddingLeft:"5px" }}>
            {this.props.children}
              </div>
          <div className='col-sm-1 col-md-2  col-lg-3'></div>
          </div>
        </div>
      </div>
    );
  }
}

export default PageWithMenu;