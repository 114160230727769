
import Tools from "./Tools";
class Validators {

    static async IsRequire(field,value){
        let req=Tools.empty(field.validator.require)?false:field.validator.require;
         if (!req){
            field.setOK();
            return true;
        }
        if (!Tools.empty(value)){
            field.setOK();
            return true;
        }
        field.setError("IsRequire");
        return false;
    }
    static async HasMinLength(field,value){
        let req=Tools.empty(field.validator.minLength)?false:true;
        if (!req){
            field.setOK();
            return true;
        }
        if (Tools.empty(value)){
            field.setError("HasMinLength");
            return false;
        }
        if (value.length<field.validator.minLength){
            field.setError("HasMinLength");
            return false;
        }
        return true;
    }
    static async IsNumeric(field,value){
        if (Tools.empty(value)){
            field.setError("HasNumeric");
            return false;
        }

        if (!(!isNaN(parseInt(value)) && isFinite(value))){
            field.setError("HasNumeric");
            return false;
        }
        return true;
    }
    static async HasMaxLength(field,value){
        let req=Tools.empty(field.validator.maxLength)?false:true;
        if (!req){
            field.setOK();
            return true;
        }
        if (Tools.empty(value)){
            field.setError("HasMaxLength");
            return false;
        }
        if (value.length>field.validator.maxLength){
            field.setError("HasMaxLength");
            return false;
        }
        return true;
    }
 
}
export default Validators;