import React, { Component } from 'react';
import BaseSlimPage from '../../../spxbasecode/cmps/BaseSlimPage.jsx';
import BaseButton from '../../../spxbasecode/cmps/BaseButton.jsx';
import Translate from '../../../Translate.jsx';

class EduMagHomePage extends Component {
  constructor(){
    super();
    this.tag="EduMagHomePage";
    this.module="EduMag";
  }
  clickButton(pageName){
    this.openPage(pageName);
  }
    openPage(pageName){
        window.App.changePage("EduMag",pageName,null);
    }
  render() {    
      let TT=Translate.getT(window.App.lang,"EduMag",this.tag);
    return (
      <BaseSlimPage isMenuShow={true}>
      
      <div className='row h-100 g-0 no-scroll'>
      <div className="row g-0 h-20"></div>
      
      <div className='row g-0 h-10'>
        <BaseButton label={TT.loginToSystem} onClick={ (event) => this.openPage('edumag_login')}></BaseButton>
      </div>
      
      
      
            <div className="row g-0 h-20"></div>
      </div>
 </BaseSlimPage>
 

    );
    /*
<div className='row g-0 h-5'>
        <BaseButton label={TT.login} onClick={(event) =>this.openPage('edumag_login')}></BaseButton>
      </div>
      <div className='row g-0 h-5'>
        <BaseButton label={TT.register} onClick={(event) =>this.openPage('edumag_register')}></BaseButton>
      </div>

    */
  }
}

export default EduMagHomePage;