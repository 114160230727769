import React, { Component } from 'react';
import { Form, Button, Alert } from 'reactstrap';
import BaseForm from '../../../spxbasecode/cmps/BaseForm';
import Translate from '../../../Translate';
import Tools from '../../../spxbasecode/Tools';
import BaseInputString from '../../../spxbasecode/cmps/BaseInputString';
import ApiCode from '../../../lib/ApiCode';
class SSOConfigForm extends Component {
  constructor(props) {
    super(props);
    this.tag="SSOConfigForm";
  }
  initForm(){
    
  }
 
  setOneCick(){
    Tools.LSSet("isDummy",true);
    window.App.changePage("EduMag","login_one_click", null);
  }

  render() {
    let TT=Translate.getT(window.App.lang,"SSO",this.tag);
    return (
      <Button color="primary" onClick={this.setOneCick}>Ustaw jedno kliknięcie</Button>
    
    );
  }
}

export default SSOConfigForm;