import  React,{ Component } from 'react';
import BasePage from './BasePage.jsx';
import BaseImg from './BaseImg.jsx';

import Menu from './Menu.jsx';
import Tools from '../Tools.jsx';
class BaseSlimPage extends Component {
    constructor(props){
        super();
        this.props=props;

    }
  render() {   
    //console.log(this.props.isMenuShow); 
    let isMenuShow=Tools.isset(this.props.isMenuShow)?this.props.isMenuShow:true;
    let logo="imgs/logo.png";
    return (
            <div className='row g-0' style={{backgroundColor:"white",height:"auto"}}>
                <Menu isShow={isMenuShow}></Menu>
                <div className='col-sm-1 col-md-2 col-lg-3'></div>
                <BasePage className='col-12 col-sm-10 col-md-8  col-lg-6' style={{ paddingLeft:"5px",paddingLeft:"5px" }}>
                <div className="row" style={{height:"50px",width:"300px"}}><BaseImg photo={logo}>

</BaseImg></div> 
                   <div className="row"  style={{height:"80%"}}>{this.props.children}</div>
                </BasePage>
               <div className='col-sm-1 col-md-2  col-lg-3'></div>
            </div>
    );
  }
}

export default BaseSlimPage;