import { SHA256 } from 'crypto-js';
import EduLog from './EduLog';
import ApiCode from './../lib/ApiCode.jsx';
Date.isLeapYear = function (year) { 
    return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
};

Date.getDaysInMonth = function (year, month) {
    return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

Date.prototype.isLeapYear = function () { 
    return Date.isLeapYear(this.getFullYear()); 
};

Date.prototype.getDaysInMonth = function () { 
    return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
};
Date.prototype.addMonths = function (value) {
    var n = this.getDate();
    this.setDate(1);
    this.setMonth(this.getMonth() + value);
    this.setDate(Math.min(n, this.getDaysInMonth()));
    return this;
};
class Tools {

    static isTrue(val){
        if (Tools.empty(val)){
            return false;
        }
        return val==1||val=="1"||val==true||val=="true";
    }
    static timestamp(){
        return Date.now();
    }

    static addMountsToDate(date,mm){
       return date.addMonths(mm);
    }

    static GetCurrentTimeStamp(){
        return  Date.now();
    }
    static DivSize(str,n){
       if (str.indexOf("px")>=1){
        str=Number.parseInt(Number.parseInt(str.replace("px",""))*n/100)+"px";
        return str;
       }
       if (str.indexOf("%")>=1){
        str=Number.parseInt(Number.parseInt(str.replace("%",""))*n/100)+"%";
        return str;
       }
       return str;
    }
    static copy(obj,isStatic=true){
        return Tools.empty(obj)?null:JSON.parse(JSON.stringify(obj));
    }
    static LSGet(key) {
        let val = localStorage.getItem(key);
      //  console.log("AAA",val);
        if (!Tools.isset(val)) {
            return null;
        }
        let js="";
        try {
            js=JSON.parse(val);
        }catch(e){
            js=val;
        }
        return js;
    }
    static LSClean(){
        localStorage.clear();
    }
    static LSHas(key) {
        let val = localStorage.getItem(key);
        return Tools.isset(val);
    }
    static LSDel(key) {
        localStorage.removeItem(key);
        
    }
    static alert(mess){
        alert(mess);
    }
    static LSSet(key, val) {
        let v = JSON.stringify(val);
        localStorage.setItem(key, v);

    }
    
    static GetLang(){
        var lang = navigator.language || navigator.userLanguage;
        let MAP={"en-US":"en_GB","pl-PL":"pl_PL"};
        if (!Tools.empty(lang)&&!Tools.empty(MAP[lang])){
            return MAP[lang];
        }
        return 'en_GB';
    }
    static isset(obj) {
        return !(typeof obj === "undefined" || obj === null);
    }
    static log(mm, mm2) {
        console.log("log", mm, mm2);
        let xx = JSON.stringify(mm);
        alert(xx);
    }
    static DeviceIdGen() {
        let obj = {};
        obj.app = window.navigator.userAgent;
        obj.rand = crypto.randomUUID();
        let hash = Tools.SHA1(JSON.stringify(obj));
        Tools.LSSet("DEVICE_ID", hash);
        return hash;
    }
    static async GetDeviceId() {
        let v = Tools.LSGet("DEVICE_ID");
        if (Tools.empty(v)) {
            let h = Tools.DeviceIdGen();
            return h;
        }
        return v;
    }
    static SHA1(str) {
        return SHA256(str).toString();

    }
    static IsFunction(obj){
        return typeof obj==='function';
    }
    static IsObject(obj){
        return typeof obj==='object';
    }
    static IsArray(obj){
        if (!Tools.IsObject(obj)){
            return false;
        }
        return obj instanceof Array;
    }
    static arrayToStringSQL(arr) {
        let str = "'" + arr.join("', '") + "'";
        return str;
    }
    static firtLeterUpper(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    static firstIndexOfText(ff, text) {
        return text.indexOf(ff);
    }
    static empty(obj) {

        if (!Tools.isset(obj)) {
            return true;
        }
        if (obj === undefined || typeof obj === 'undefined' ||
            obj === null ||
            obj === "null" ||
            obj === "" || obj === "[]") {
            return true;
        }
        if (obj.length === 0) {
            return true;
        }
        return false;

    }
    static SendToApi(url, data, success, error) {
        //console.log("url",url,data);
        let formData = new FormData();
        for(let k in data){
        formData.append(k, data[k]);
        }

        fetch(url, {
            method: 'POST',
            headers: {
               // 'Content-Type': 'application/json'
            },
            body:formData,
           // body: JSON.stringify(data),
            mode: 'cors'
        })
            .then(response => response.json())
            .then(data2 => {
            //Tools.log(data2);
             //   console.log("succ",data2);
                let code=data2.code;
                //Tools.log(code);
                if (code==ApiCode.OK){
                    success(data2);
                        
                }else if (code==ApiCode.ERROR){
                    error(data2);
                }

                 
            })
            .catch(error2 => {

                error(error2);
              //  Tools.log(error2);
                console.error("EEr",error2);
            });
          
    }
    static SendToApi2(url, data, success, error) {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url,false);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
         xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
         xhr.setRequestHeader("Access-Control-Allow-Methods", "GET, POST, OPTIONS");
         xhr.setRequestHeader(
            "Access-Control-Allow-Headers",
            "Access-Control-Allow-Origin,Option, Content-Type, Authorization, X-Content-Type-Options, Accept, X-Requested-With, Origin, Access-Control-Request-Method, Access-Control-Request-Headers"
          );
          xhr.setRequestHeader("Access-Control-Allow-Credentials", true);
          xhr.setRequestHeader("Access-Control-Allow-Private-Network", true);
        
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                let text = xhr.responseText;
                //console.log(text);
                let resp=null;
                try{
                resp = JSON.parse(text);
                success(resp);
                }catch(e){
                  //  EduLog.AddLog("Tools::SendToApi",{"url":url,"data":data,"resp":resp});
                }
            } else if (xhr.readyState === 4 && xhr.status !== 200) {
                // Handle error
                error(xhr.responseText);
            }
        }; 
        xhr.send(JSON.stringify(data));
    }
}
export default Tools;