import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import PageWithMenu from '../../../spxbasecode/cmps/PageWithMenu.jsx';
import EduMagQuickGenSSCCCmp from '../cmp/EduMagQuickGenSSCCCmp.jsx';
import Translate from '../../../Translate.jsx';

class EduMagQuickGenSSCCPage extends Component {
  constructor() {
    super();
    let this2 = this;
    this.tag = "EduMagQuickGenSSCCPage";
    this.module = "EduMag";
  }
  back(){
    window.App.goBack();
  }
  render() {
    let TT = Translate.getT(window.App.lang, this.module, this.tag);
    return (
      <PageWithMenu showDummy="true" style={{backgroundColor:"white",height:"100%"}}>      
      <Container className="my-4" style={{backgroundColor:"white",height:"100%"}}>
     <EduMagQuickGenSSCCCmp style={{backgroundColor:"white",height:"100%"}}></EduMagQuickGenSSCCCmp>
      </Container>
    </PageWithMenu>  
    );
  }
}

export default EduMagQuickGenSSCCPage;