import React, { Component } from 'react';
import BaseForm from '../../../spxbasecode/cmps/BaseForm';
import Translate from '../../../Translate';
import BaseInputString from '../../../spxbasecode/cmps/BaseInputString';
import ApiCode from '../../../lib/ApiCode';
class SSOLoginForm extends Component {
  constructor(props) {
    super(props);
    this.tag="SSOLoginForm";
    this.state = {
      login: '',
      pin: '',
      error: null
    };
    this.form={vals:{login:""}};
  }
  initForm(){
    
  }
  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = event => {
    event.preventDefault();
    
   
  }
  succ(ss){
    let this2=this;
    let ss2={code:200,data:ss};
    window.App.loginToSystem(ss2,function(){
      let params=[];
      window.App.changePage("EduMag","sso_config", params);
    },function(ee){
      console.log(this2.form,this2);
    });  
 }

  render() {
    let TT=Translate.getT(window.App.lang,"SSO",this.tag);
    
    return (
      <BaseForm className="row" parent={this}
      onSucc={this.succ}  act="loginToSystem" module="sso"
      >
        <BaseInputString name="login" id="login" label={TT.login.label} placeholder={TT.login.placeholder} 
        parent={this} require="true" minlength="3" maxlength="20"/>
        <BaseInputString type="passwordNumber" name="pin" id="pin" label={TT.pin.label} placeholder={TT.pin.placeholder} 
        parent={this} require="true" minlength="4" maxlength="8"/>
    
      </BaseForm>
    );
  }
}

export default SSOLoginForm;