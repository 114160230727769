import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import BaseSlimPage from '../../../spxbasecode/cmps/BaseSlimPage.jsx';
//import BaseButton from '../../Base/cmps/BaseButton.jsx';
import Translate from '../../../Translate.jsx';
import SSORegisterForm from '../../SSO/cmp/SSORegisterForm.jsx';
class EduMagLoginPage extends Component {
  constructor() {
    super();
    let this2 = this;
    this.tag = "EduMagRegisterPage";
    this.module = "EduMag";
  }
  back(){
    window.App.goBack();
  }
  render() {
    let TT = Translate.getT(window.App.lang, this.module, this.tag);
    return (
      <BaseSlimPage>      
        <Container className="my-4">
     <SSORegisterForm className="registerForm"></SSORegisterForm>
      </Container>
      </BaseSlimPage>
    );
  }
}

export default EduMagLoginPage;