import React, { Component } from 'react';
import BaseSlimPage from '../../../spxbasecode/cmps/BaseSlimPage.jsx';
import BaseButton from '../../../spxbasecode/cmps/BaseButton.jsx';
import Translate from '../../../Translate.jsx';

class NoSoundPage extends Component {
    constructor(){
    super();
    
    this.tag="NoSoundPage";
    this.module="EduMag";
    this.returnPage='logo';
  }
  openPage(pageName){
    window.App.changePage("EduMag",pageName,null);
    window.App.Config.lastPlaySound=null;
    window.App.textToRead.state.isPlaying=false;
    window.App.textToRead.play();
}
  render() {    
    alert("DUPA3");
    let TT=Translate.getT(window.App.lang,"EduMag",this.tag);
    return (
      <BaseSlimPage isMenuShow={false}>
       <div className='row g-0 h-100'>
        <BaseButton label={TT.no_sound} onClick={ (event) =>this.openPage(this.returnPage)}></BaseButton>
      </div>
 </BaseSlimPage>
    );
  }
}

export default NoSoundPage;