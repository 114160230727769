import React, { Component } from 'react';
import BaseForm from '../../../spxbasecode/cmps/BaseForm';
import Translate from '../../../Translate';
import Tools from '../../../spxbasecode/Tools.jsx';
import BaseInputString from '../../../spxbasecode/cmps/BaseInputString';
import BaseImage from '../../../spxbasecode/cmps/BaseImage';
import BaseButtonIcon from '../../../spxbasecode/cmps/BaseButtonIcon';
class SSOLoginOneClickForm extends Component {
  constructor(props) {
    super(props);
    this.tag="SSOLoginOneClicForm";
    this.state = {
      login: '',
      password: '',
      error: null
    };
    this.form={vals:{login:""}};
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  loginToSystem(login,pin){
    window.App.API.exec("sso","loginToSystem",{"login":login,"pin":pin},(ss)=>{
      let ss2=ss;
      window.App.loginToSystem(ss2,function(){
        let params=[];
        window.App.changePage("EduMag","edumag_quick_gen_sscc", params);
      },function(ee){
        console.log("XxxxXX3eeee",ee);
      });  
   },(ee)=>{
    console.log("XxxxXX3sssssseeee",ee);
   });
  }
  loginWorker1 = event => {
    this.loginToSystem("worker1",12345678);
  }
  loginWorker2 = event => {
    this.loginToSystem("worker2",87654321);
    
  }
  

  render() {
    let TT=Translate.getT(window.App.lang,"SSO",this.tag);
    return (
      <div className='row' style={{height:"100%",backgroundColor:"white"}}>
        <BaseButtonIcon backgroundColor="gray" className="row" width="100%"  height="40%" onClick={this.loginWorker1} photo="worker1.png" label="Pracownik 1"/>
        <BaseButtonIcon backgroundColor="gray" className="row" width="100%"  height="40%" onClick={this.loginWorker2} photo="worker2.png" label="Pracownik 2"/>   
      </div>
    );
  }
}

export default SSOLoginOneClickForm;