import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import PageWithMenu from '../../../spxbasecode/cmps/PageWithMenu.jsx';
import SSOConfigForm from '../cmp/SSOConfigForm.jsx';
class SSOConfigPage extends Component {
  constructor(){
    super();
    this.tag="SSOConfigPage";
    this.module="SSO";
  }
  clickButton(pageName){
    this.openPage(pageName);
  }
  initForm(){
    
  }
    openPage(pageName){
        window.App.changePage("EduMag",pageName,null);
    }
  render() {    
    return (
     <PageWithMenu showDummy="false">      
      <Container className="my-4">
     <SSOConfigForm></SSOConfigForm>
      </Container>
    </PageWithMenu>
    )
  }
}

export default SSOConfigPage;