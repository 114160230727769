import React from 'react';
export class EduMagPrintingSSCC extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            htmlPrint:props.htmlPrint
          };
    }
    render() {
        let hh=this.state.htmlPrint;     
      return (
        <div dangerouslySetInnerHTML={ { __html: hh}} style={{backgroundColor:"white",width:"99vw",height:"97vh"}}></div>
      );
    }
  }