import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Tools from '../Tools.jsx';
import BaseImg from './BaseImg.jsx';
class BaseButtonIcon extends Component {
    constructor(props) {
        super();
        this.states = {};
        this.initFromProps(props);
    }
    initFromProps(props) {
        if (Tools.empty(props)) {
            return;
        }
        //this.setState("onClick",props.onClick);
        this.states.onClick = props.onClick;
        this.states.backgroundColor = !Tools.empty(props) && !Tools.empty(props.backgroundColor) ? props.backgroundColor : "inherit";
        this.states.label = !Tools.empty(props) && !Tools.empty(props.label) ? props.label : "";
        this.states.width = !Tools.empty(props) && !Tools.empty(props.width) ? props.width :"250px";
        this.states.height = !Tools.empty(props) && !Tools.empty(props.height) ? props.height :"50px";
        this.states.photo = !Tools.empty(props) && !Tools.empty(props.photo) ? props.photo :null;
        
        
    }
    render() {
        this.initFromProps(this.props);
        let props = this.states;
       
        let h100=Tools.copy(props.height,true);
        //console.log(props.height);
        let h2=Tools.DivSize(props.height,75);
        let h25=Tools.DivSize(props.height,25);
        let w2= Tools.DivSize(props.width,50);
        let w4=Tools.DivSize(props.width,25);
        return (

            <div style={{ margin:"5px",borderStyle:"solid",borderColor:"black",width:props.width, height:props.height, 
            backgroundColor: props.backgroundColor }} 
            onClick={(event) => props.onClick()} className="font-weight-bold"
            >
                <div className="row" style={{height:h2}}>
                    <div style={{width:w4}}> </div>
                <BaseImg photo={props.photo} width={w2} height="100%">

                </BaseImg>
                <div style={{width:w4}}> </div>
                </div>
                <div className="row" style={{height:h25,color:"white"}}>
                {props.label}
                </div>
            </div>
        );
    }
}

export default BaseButtonIcon;