import React, { Component } from 'react';
import BaseAnimation from '../../../spxbasecode/cmps/BaseAnimation.jsx';
import LogoEdupanel from '../../../spxbasecode/cmps/LogoEdupanel.jsx';
import Tools from '../../../spxbasecode/Tools.jsx';
import Translate from '../../../Translate.jsx';

class EduMagLogoPage extends Component {
  constructor() {
    super();
    let this2 = this;
    this.tag="EduMagLogoPage";
    this.module="EduMag";
    window.App.readText(this.module,this.tag,[{tag:"text1"}]);
    
  }
  clickButton(pageName) {
   // this.openPage(pageName);
  }
  openPage(pageName) {
    window.App.changePage("EduMag", pageName, null);
  }
  onEndAnimation(){
 //   window.App.changePage("EduMag","home", null);
  }
  render() {
    let TT = Translate.getT(window.App.lang, "EduMag", "LogoPage");
    let time=1000;
    let components = [
     { component: LogoEdupanel, time:30000 }
    ];
    setTimeout(function(){
    let kk=Tools.LSGet("isDummy");
    if (Tools.isTrue(kk)){
      window.App.changePage("EduMag","login_one_click", null);
    }else{
      window.App.changePage("EduMag","login", null);   
    }},1500);
    /*
    <BaseSlimPage >
        <div className='row h-100 g-0 no-scroll'>
          <div className="row g-0 h-40"></div>
          <div className='row g-0 h-20'>
            <BaseAnimation components={components} onEnd={this.onEndAnimation}/>
          </div>
          <div className="row g-0 h-40"></div>
        </div>
      </BaseSlimPage>

    */
    return (
        <div style={{backgroundColor:"white",display:"block"}} className='row h-100 g-0 no-scroll'>
          <div className='row h-20'></div>
          <div className='row h-50'>
            <div className='d-hide col-sm-2 col-md-3'></div>
            <BaseAnimation  className='col-xs-12 col-sm-8 col-md-6 g-10 h-100' components={components} onEnd={this.onEndAnimation}/>
          </div>
        </div>
        
    

    );
  }
}

export default EduMagLogoPage;