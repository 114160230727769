import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import PageWithMenu from '../../../spxbasecode/cmps/PageWithMenu.jsx';
import SSOLoginOneClickForm from '../cmp/SSOLoginOneClickForm.jsx';
class SSOLoginOneClickPage extends Component {
  constructor(){
    super();
    this.tag="SSOLoginPage";
    this.module="SSO";
  }
  clickButton(pageName){
    this.openPage(pageName);
  }
    openPage(pageName){
        window.App.changePage("EduMag",pageName,null);
    }
  render() {    
    return (
      <PageWithMenu style={{height:"100%"}} showDummy="false">      
      <Container style={{height:"100%"}} className="my-4">
      <SSOLoginOneClickForm style={{height:"100%"}}></SSOLoginOneClickForm>
      </Container>
    </PageWithMenu>
     
    )
  }
}

export default SSOLoginOneClickPage;