import React, { Component } from 'react';
import BaseForm from '../../../spxbasecode/cmps/BaseForm';
import Translate from '../../../Translate';
import Tools from '../../../spxbasecode/Tools';
import BaseInputString from '../../../spxbasecode/cmps/BaseInputString';
import BaseImage from '../../../spxbasecode/cmps/BaseImage';
import BaseButtonIcon from '../../../spxbasecode/cmps/BaseButtonIcon';
import { EduMagPrintingSSCC } from './EduMagPrintingSSCC';
import ReactToPrint from 'react-to-print';

class EduMagPrintSSCCCmp extends Component {
  constructor(props) {
    super(props);
    this.tag="EduMagPrintSSCCCmp";
    this.module="EduMag";
    let params=window.App.pageParams;
    console.log("pppp",window.App.pageParams);
    this.state = {
      htmlPrint:params.htmlPrint
    };
    this.state.productionDate=new Date().toISOString().slice(0, 10);
    this.form={vals:{login:"aaa"}};
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  succ(e){
    
    let params=[];
    window.App.changePage("EduMag","edumag_quick_gen_sscc", params);


  }
  initForm(){
    let this2=this;

    setTimeout(()=>{
      if (window.App.Config.isDev){
        this2.form.init(this2.state);
      }
    },300);
  }
  print = event => {
    event.preventDefault();
    console.log("print");
   
  }
  render() {
    let TT=Translate.getT(window.App.lang,this.module,this.tag);
  //  console.log(TT);
  //  return (<div></div>);
  setTimeout(function(){
    document.getElementById("bt1").click();
  },1000);
  let hh=this.state.htmlPrint;
    return (
      <div className='row h100'>
        
        <EduMagPrintingSSCC style={{backgroudColor:"green"}} htmlPrint={hh} ref={el => (this.componentRef = el)} />
        <ReactToPrint style={{position:'absolute',top:0,left:0}} copyStyles={false}
          trigger={() => {
            let pp2=window.App.pageParams;
          let pp={dt:Tools.timestamp(),module:"sscc",pageName:"EduMagPrintedSSCCPage",
          sscc:pp2.sscc,productId:pp2.productId};
             Tools.LSSet("nextPage",pp);
            return <a style={{position:'absolute',top:0,left:0}} id="bt1" className='button edubutton' href="#"><div className='button success'>Drukuj</div></a>;
          }}
          content={() => this.componentRef}
        />
      </div>
    );
  }
}
export default EduMagPrintSSCCCmp;