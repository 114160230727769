import React, { Component } from 'react';
import Tools from './../spxbasecode/Tools.jsx';


class API extends Component {
  constructor(props) {
    super();
    this.props = props;
   
    this.api = this.getAPI();
  }
  static IsProd() {
    //console.log(window.App.Config.isProd);
    return window.App.Config.isProd;
    //console.log(window.location.host);
    return ["localhost", "edumag.edupanel.pl", "edupanel.pl"].indexOf(window.location.host) >= 0;
  }
  static IsDev() {
    return window.App.Config.isDev;
  }
  getAPI() {

    if (API.IsProd()) {
      return window.App.Config.API_HOST_PROD;
    } else {
      return window.App.Config.API_HOST_DEV;
    }
  }
  async addBaseParams(params) {
    if (Tools.empty(params)) {
      params = {};
    }
    if (!(Tools.IsArray(params) || Tools.IsObject(params))) {
      //console.log(params);
      return null;
    }

    params.devid = await Tools.GetDeviceId();
    params.sessid=Tools.LSGet("sessid");
    return params;
  }
  async exec(module, name, params, success, error) {

    let path = this.api + "/" + module + "/" + name;
    //(params);
    //Tools.log(path);
    params = await this.addBaseParams(params);
    //console.log("pppppppssss",params);
    Tools.SendToApi(path, params, success, error);
    /*  fetch(path, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(params),
          mode: 'cors'
      })
          .then(response => response.json())
          .then(data => {
          Tools.log(data);
              console.log(data);
          })
          .catch(error => {
          Tools.log(error);
              console.error(error);
          });
          */
  }
  render() {
    return (<div></div>
    );
  }
}

export default API;