import React, { Component } from 'react';
import BaseForm from '../../../spxbasecode/cmps/BaseForm';
import Translate from '../../../Translate';
import BaseInputString from '../../../spxbasecode/cmps/BaseInputString';
class SSORegisterForm extends Component {
  constructor(props) {
    super(props);
    this.module="SSO";
    this.tag="SSORegisterForm";
    this.state = {
      login: 'admin',
      pin: '1234',
      repin: '1234',
      firstName:'Stanisław',
      lastName:'Paska',
  //    error: null
    };
    this.form={vals:{login:"aaa"}};
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  initForm(){
    let this2=this;
    setTimeout(()=>{
      if (window.App.Config.isDev){
        this2.form.init(this2.state);
      }
    },300);
  }
  handleSubmit = event => {
    event.preventDefault();
    
   
  }
  render() {
    let TT=Translate.getT(window.App.lang,"SSO",this.tag);
    return (
      <BaseForm className="row" parent={this}>
        <BaseInputString module={this.module} pageName={this.pageName} 
        name="firstName" id="firstName" label={TT.firstName.label} 
        type="string"
        placeholder={TT.firstName.placeholder} 
        parent={this} require="true" minlength="3" maxlength="20"/>
        <BaseInputString module={this.module} pageName={this.pageName} 
       type="string"
       name="lastName" id="lastName" label={TT.lastName.label} 
        placeholder={TT.lastName.placeholder} 
        parent={this} require="true" minlength="3" maxlength="20"/> 
        <BaseInputString module={this.module} pageName={this.pageName}
         type="select" list="WorkerType" name="workerType" id="workerType"
          label={TT.workerType.label}
          placeholder={TT.workerType.placeholder} 
        parent={this} require="true"/>
        <BaseInputString module={this.module} pageName={this.pageName} 
        type="string"
        name="login" id="login" label={TT.login.label} 
        placeholder={TT.login.placeholder} 
        parent={this} require="true" minlength="5" maxlength="20"/>
        <BaseInputString module={this.module} pageName={this.pageName}
         type="passwordNumber" name="pin" id="pin" label={TT.pin.label}
          placeholder={TT.pin.placeholder} 
        parent={this} require="true" minlength="4" maxlength="8"/>
        <BaseInputString module={this.module} pageName={this.pageName} 
        type="passwordNumber" name="repin" id="repin" label={TT.repin.label}
         placeholder={TT.repin.placeholder} 
        parent={this} require="true" minlength="4" maxlength="8"/>
    
      </BaseForm>
    );
  }
}

export default SSORegisterForm;