import React, { Component } from 'react';
import './App.css';
import API from './lib/API.jsx';
import Tools from './spxbasecode/Tools.jsx';
import EduMagLogoPage from './Module/EduMag/Page/EduMagLogoPage';
import EduMagHomePage from './Module/EduMag/Page/EduMagHomePage';
import EduMagLoginPage from './Module/EduMag/Page/EduMagLoginPage';
import EduMagRegisterPage from './Module/EduMag/Page/EduMagRegisterPage';
import EduMagLoadFromFilePage from './Module/EduMag/Page/EduMagLoadFromFilePage';
import EduMagAboutUsPage from './Module/EduMag/Page/EduMagAboutUsPage';
import SSOLoginPage from './Module/SSO/Page/SSOLoginPage';
import SSOConfigPage from './Module/SSO/Page/SSOConfigPage';
import SSOLoginOneClickPage from './Module/SSO/Page/SSOLoginOneClickPage';
import EduMagQuickGenSSCCPage from './Module/EduMag/Page/EduMagQuickGenSSCCPage';
import EduMagPrintSSCCPage from './Module/EduMag/Page/EduMagPrintSSCCPage';
import EduMagPrintedSSCCPage from './Module/EduMag/Page/EduMagPrintedSSCCPage';

import Translate from './Translate';
import NoPage from './Module/EduMag/Page/NoPage';
import AdvPage from './Module/EduMag/Page/AdvPage';
import FundPage from './Module/EduMag/Page/FundPage';
import NoInternetPage from './Module/EduMag/Page/NoInternetPage';
import UpdatePage from './Module/EduMag/Page/UpdatePage';
import NoSoundPage from './Module/EduMag/Page/NoSoundPage';
import { createBrowserHistory } from 'history';
import ApiCode from './lib/ApiCode';


function DynamicTag(props) {
    if (props.tag === "EduMagHomePage") {
        return (<EduMagHomePage></EduMagHomePage>);
    } else if (props.tag === "EduMagLogoPage") {
        return (<EduMagLogoPage></EduMagLogoPage>);
    } else if (props.tag === "EduMagQuickGenSSCCPage") {
        return (<EduMagQuickGenSSCCPage></EduMagQuickGenSSCCPage>);
    }
    else if (props.tag === "EduMagPrintSSCCPage") {
        return (<EduMagPrintSSCCPage />);
    }
    else if (props.tag === "EduMagPrintedSSCCPage") {
        return (<EduMagPrintedSSCCPage />);
    }
    else if (props.tag === "EduMagLoginPage") {
        return (<EduMagLoginPage></EduMagLoginPage>);
    }
    else if (props.tag === "EduMagRegisterPage") {
        return (<EduMagRegisterPage></EduMagRegisterPage>);
    }
    else if (props.tag === "EduMagLoadFromFilePage") {
        return (<EduMagLoadFromFilePage></EduMagLoadFromFilePage>);
    }
    else if (props.tag === "EduMagAboutUsPage") {
        return (<EduMagAboutUsPage></EduMagAboutUsPage>);
    }
       else if (props.tag === "NoSoundPage") {
        return (<NoSoundPage></NoSoundPage>);
    }
    else if (props.tag === "SSOLoginPage") {
        return (<SSOLoginPage />);
    }
    else if (props.tag === "SSOConfigPage") {
        return (<SSOConfigPage />);
    }
    else if (props.tag === "SSOLoginOneClickPage") {
        return (<SSOLoginOneClickPage />);
    }
    else if (props.tag === "AdvPage") {
        return (<AdvPage />);
    }
    else if (props.tag === "FundPage") {
        return (<FundPage />);
    }
    else if (props.tag === "NoInternetPage") {
        return (<NoInternetPage />);
    }
    else if (props.tag === "UpdatePage") {
        return (<UpdatePage />);
    }
    else {
        return (<NoPage></NoPage>);
    }
}

class App extends Component {
    PAGES_MAP = {
        ads:"AdvPage",
        fund:"FundPage",
        no_intenet:"NoInternetPage",
        update:"UpdatePage",
        "": "EduMagLogoPage",
        edumag_load_from_file:"EduMagLoadFromFilePage",
        edumag_login:"EduMagLoginPage",
        edumag_register:"EduMagRegisterPage",
        logo: "EduMagLogoPage",
        nosound: "NoSoundPage",
        edumag_home: "EduMagHomePage",
        edumag_quick_gen_sscc: "EduMagQuickGenSSCCPage",
        edumag_print_sscc: "EduMagPrintSSCCPage",
        edumag_printed_sscc: "EduMagPrintedSSCCPage",
        login:"SSOLoginPage",
        sso_config:"SSOConfigPage",
        login_one_click:"SSOLoginOneClickPage",

    };
    logout(){
        let MAP=["sessid","firstName","lastName"];
        for(let k in MAP){
            let kk=MAP[k];
            Tools.LSDel(kk);
        }
        this.goHome();

    }
    goHome(){
        window.App.changePage(window.App.Config.HOME_MODULE,window.App.Config.HOME_PAGE_NAME, {});
    }
    constructor() {
        super();
        Tools.GetDeviceId().then(id => {
            this.DeviceID = id;
        });
        this.langs=["pl_PL","en_GB"];
        window.App = this;
        this.pageParams={};
       // Tools.LSClean();
        this.textToCommand = null;
        this.lang = Tools.GetLang();
        this.lang = "pl_PL";
        this.history = createBrowserHistory();
        let TT=Translate.getT(window.App.lang,"EduMag","EduMagHomePage");
        this.historyBrows=[];
        this.Config = {
            isProd:true,
            isDev:false,
            HOME_PAGE_NAME:"edumag_home",
            HOME_MODULE:"EduMag",
            BASE_PAGE:"EduMagLogoPage",
           // BASE_PAGE:"SSOLoginOneClickPage",
      //      isProd:false,
        //    isDev:true,
            backgroundColor:"white",
            HOSTS_MAP:["localhost", "edumag.edupanel.pl", "edupanel.pl"],
          //  API_HOST_PROD: "https://eduapi.edupanel.pl",
            API_HOST_PROD: "eduapi/public/index.php",
            API_HOST_DEV: "http://localhost/eduapi/public/index.php",
            ver:"1.0",
            isMenuShow:false,
            menu:[{ title: TT.login, action: (event) =>window.App.changePage(null,"login") },
            { title: TT.register, action: (event) =>window.App.changePage(null,"regiser") },
            { title: TT.logout, action: (event) =>window.App.changePage(null,"logout") }
        ]
        };
        window.App.Config.isMenuLeave = false;
        window.App.Config.isPlayAudio=false;
        this.tag = this.Config.BASE_PAGE;
        if (!this.getNextPage()){
        // this.tag = this.getTagFromUrl();
        }
        //this.tag = "FundPage";
       // this.tag = "SSOLoginPage";
        this.isBlind = true;
        //this.isInteract=false;
        this.handleBackButton();
        let this2 = this;
        this.API = new API();
        this.getBaseInfo(function(){});
      /*  this.API.exec("sso","getVer",null,(ss)=>{
            let xx=!Tools.empty(ss.data)&&!Tools.empty(ss.data.ver)?parseFloat(ss.data.ver):null;
            let ver=parseFloat(this2.Config.ver);
          
            if (Tools.empty(xx)){
                window.App.changePage(null,"no_internet");
                return;
            }else if (xx>ver){
                window.App.changePage(null,"update");
                return;
            }
            
           
        },(ee)=>{
            window.App.changePage(null,"no_internet");
        });
        */
    }
    loginToSystem(ss,succ,err){
        console.log('sssss',ss);
            if (ss.code==ApiCode.ERROR){
                err();
                return;
            }
            let dd=ss.data;
            Tools.LSSet('firstName',dd.first_name);
            Tools.LSSet('lastName',dd.last_name);
            Tools.LSSet('sessid',dd.session_id);
            setTimeout(function(){
            succ();
            },500);
            return;
            
            
            }
    getNextPage(){
        let pp=Tools.LSGet("nextPage");
        if (Tools.empty(pp)){
            return false;
        }
        if ((Tools.timestamp()-pp.dt)>=5){
            Tools.LSDel("nextPage");
            return false;
        }
        console.log("pp",pp);
        this.tag=pp.pageName;
        this.pageParams=pp;
        Tools.LSDel("nextPage");
        return true;
    }
    getTagFromUrl(){
        let path=window.location.pathname;
        if (window.location.href.indexOf("#")>=1){
            return this.Config.BASE_PAGE;
        }
        console.log("path",path);
        if (path.length<=2){
            return this.Config.BASE_PAGE;
        }
        let xx=path.split("/");
        if (xx.length<=2){
            return this.Config.BASE_PAGE;
        }
        let kk=xx[2];
        let zz=this.PAGES_MAP[kk];
        console.log(path,xx,kk,zz);
        return zz;
        }
    getBaseInfo(onSuccess){
        //window.App.changePage(null,"load");
        let lists=["workerType"];
        let hasLoad=true;
        for(let k in lists){
            let el=this.lang+"_"+lists[k];
            if(!Tools.LSHas(el)){
                hasLoad=false;
                break;
            }
        }
        if (hasLoad){
            onSuccess();
        }else{
            let this2=this;
            this.API.exec("core","baseInfo",null,(ss)=>{
               let dd=ss.data;
               Tools.LSSet(this2.lang+"_"+"workerType",dd.workerType);
               Tools.LSSet(this2.lang+"_"+"products",dd.products);
               
               onSuccess();
               
            },(ee)=>{
                window.App.changePage(null,"no_internet");
                onSuccess();
            });
        }
    }
    getList(listName,onSuccess){
        
        let kl=this.lang+"_"+listName;
        let ls=Tools.LSGet(kl);
        if (ls!==null){
            setTimeout(function(){
                onSuccess(ls);
                },500);
            return;
        }
        setTimeout(function(){
        onSuccess(ls);
        },500);
      //  this.API.
    }
    handleBackButton() {
        if (window.history && window.history.pushState) {
            let this2 = this;
            window.addEventListener('popstate', function () {
                let pageName = window.location.pathname.substring(1);
                this2.changePage(null, pageName);
            });
        }
    }
    TTCInit() {
        this.textToCommand.MAP = {
       
        };
    }
    tryPlay() {
        this.play();
    }
    play() {
       // this.textToRead.isInteract = true;
     //   this.textToRead.play();
    }
    readText(modle, pageName, tag) {
        let this2 = this;
        setTimeout(() => {
            //this2.textToRead.read(modle, pageName, tag);
            //this2.tryPlay();
        }, 150);

    }
    render() {

        const customTag = this.tag;
    console.log("xccccccccccs",customTag);
        return (
            <div style={{ height: "100%", width: "100%"}}>
                <div className='row g-0' style={{ height: "100%" }}>
                    <DynamicTag tag={customTag} />
                </div>
                
            </div>
        );

    }
    getTag(pageName) {
        return this.PAGES_MAP[pageName];
    }
    goBack(){
        let n=this.historyBrows.length;
        let k=n-2;
        if (k<0){
            k=0;
        }
        let p=this.historyBrows[k];
        this.changePage(p.module,p.pageName);
    }
    goAds(module,pageName,params){
        if (["ads"].indexOf(pageName)>=0){
            return false;
        }
        let zz=["AdvPage"].indexOf(this.tag);
        if (zz>=0){
            return false;
        } 
        let k=["edumag_new_game"].indexOf(pageName);
        
        if (!(k>=0)){
            return false;
        }
        window.App.goNextPageInfo={module:module,pageName:pageName,params:params};
        this.changePage(null,"ads",params);
        return true;
    }
    goFund(module,pageName,params){
        if (["fund"].indexOf(pageName)>=0){
            return false;
        }
        let zz=["FundPage"].indexOf(this.tag);
        if (zz>=0){
            return false;
        } 
        let k=["edumag_new_game"].indexOf(pageName);
        
        if (!(k>=0)){
            return false;
        }
        window.App.goNextPageInfo={module:module,pageName:pageName,params:params};
        this.changePage(null,"fund",params);
        return true;
    }
   
    changePage(module, pageName, params) {
        /*let xx=this.goAds(module,pageName, params);
        if (xx){
            return;
        }*/
        let xx=this.goFund(module,pageName, params);
        if (xx){
            return;
        }
        //console.log("changePage1",pageName);
        let tag = this.getTag(pageName);
        //console.log("changePage2",tag);
        this.tag = tag;
        let path = "/" + pageName;
        this.pageParams=params;
       // console.log("zzzz",pageName,tag);
        this.historyBrows.push({module:module,pageName:pageName});
        //this.history.push(path);
        this.forceUpdate();
    }
    changeLangToNext(){
        let i=this.langs.indexOf(this.lang);
        let n=this.langs.length;
        let k=(i+1)%n;
        this.lang=this.langs[k];
        this.changeLang(this.lang);    
    }
    changeLang(lang) {
        this.lang = lang;
        this.forceUpdate();
    }
}

export default App;
