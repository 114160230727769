import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import PageWithMenu from '../../../spxbasecode/cmps/PageWithMenu.jsx';
import EduMagPrintSSCCCmp from '../cmp/EduMagPrintSSCCCmp.jsx';
import Translate from '../../../Translate.jsx';

class EduMagPrintSSCCPage extends Component {
  constructor() {
    super();
    let this2 = this;
    this.tag = "EduMagPrintSSCCPage";
    this.module = "EduMag";
  }
  back(){
    window.App.goBack();
  }
  render() {
    let TT = Translate.getT(window.App.lang, this.module, this.tag);
   
    return (
      <PageWithMenu showDummy="true" style={{backgroundColor:"white",height:"100%"}}>      
      <Container className="my-4" style={{backgroundColor:"white",height:"100%"}}>
     <EduMagPrintSSCCCmp style={{backgroundColor:"white",height:"100%"}} />
      </Container>
    </PageWithMenu>  
    );
    
  }
}

export default EduMagPrintSSCCPage;