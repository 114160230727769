import { Component } from 'react';
class Translate_pl_PL extends Component {
    static Trans = {
            Error:{
                Error:{
                    IsRequire:'Pole "$label$" nie może być puste!',
                    HasNumeric : 'Pole "$label$" musi się składać z samych cyfr',
                    HasMinLength:'Pole "$label$" nie może być krótrze niż $minLength$ znaków!',
                    HasMaxLength:'Pole "$label$" nie może być dłuższe niż $minLength$ znaków!',
                }
            },
            SSO:{
                SSOLoginForm:{
                    login:{
                        label:"Login",
                        placeholder:"Login"
                    },
                    pin:{
                        label:"PIN",
                        placeholder:"PIN"
                    },
                    loginToSystem:{
                        label:"Zaloguj się"
                    },
                    register:{
                        label:"Zarejestruj się"
                    },
                },
                SSORegisterForm:{
                    login:{
                        label:"Login",
                        placeholder:"Login"
                    },
                    firstName:{
                        label:"Imię",
                        placeholder:"Imię"
                    },
                    lastName:{
                        label:"Nazwisko",
                        placeholder:"Nazwisko"
                    },
                    workerType:{
                        label:"Typ pracownika",
                        placeholder:"Typ pracownika"
                    },
                    pin:{
                        label:"PIN",
                        placeholder:"PIN"
                    },
                    repin:{
                        label:"Powtórz PIN",
                        placeholder:"Powtórz PIN"
                    },
                    register:{
                        label:"Zarejestruj się"
                    },
                }
            },
            EduMag: {
                EduMagPrintSSCCCmp:{
                    htmlPrint:{
                        label:"Podgląd",
                        placeholder:"Podaj numer SSCC"
                    }
                },
                EduMagQuickGenSSCCCmp:{
                    sscc:{
                        label:"Numer SSCC",
                        placeholder:"Podaj numer SSCC"
                    },
                    productId:{
                        label:"Produkt",
                        placeholder:"Wybierz produkt"
                    },
                    productionDate:{
                        label:"Data produkcji",
                        placeholder:"Data produkcji"
                    },
                    validDate:{
                        label:"Data ważności",
                        placeholder:"Data ważności"
                    },
                    generate:{
                        label:"Generuj etykietę",
                        placeholder:"Generuj etykietę"
                    },
                    firstName:{
                        label:"Imię",
                        placeholder:"Imię"
                    },
                    lastName:{
                        label:"Nazwisko",
                        placeholder:"Nazwisko"
                    },
                    workerType:{
                        label:"Typ pracownika",
                        placeholder:"Typ pracownika"
                    },
                    pin:{
                        label:"PIN",
                        placeholder:"PIN"
                    },
                    repin:{
                        label:"Powtórz PIN",
                        placeholder:"Powtórz PIN"
                    },
                    register:{
                        label:"Zarejestruj się"
                    },
                },
                EduMagHomePage: {
                    loadFromFile: "Załaduj z pliku",
                    register: "Zarejestruj się",
                    loginToSystem: "Zaloguj się",
                    change_lang: "Zmień język",
                    how_to_play: "Zasady gry",
                    about_us: "O nas"
                },
                NoSoundPage:{
                    no_sound:"Naciśnij przycisk aby przejść dalej"
                },EduMagAboutUsPage: {
                    title: "O nas",
text: "Tworzymy wysokiej jakości aplikacje i gry edukacyjne dla osób niepełnosprawnych, zwłaszcza dla niewidomych. Naszym celem jest ułatwienie życia i poszerzenie wiedzy naszych użytkowników. Rozumiemy, że rozwój oprogramowania wymaga zasobów finansowych, dlatego potrzebujemy wsparcia, aby kontynuować tworzenie innowacyjnych rozwiązań. Jesteśmy wdzięczni za każdą pomoc i wsparcie finansowe, które otrzymujemy. Darczyńcy, którzy rozumieją znaczenie naszej misji, przyczyniają się do rozwoju naszych projektów i umożliwiają nam maksymalny wpływ na społeczność osób niepełnosprawnych. Poprzez wsparcie organizacji Edupanel.pl, darczyńcy stają się częścią naszej społeczności, która dąży do eliminowania barier i tworzenia bardziej włączającego świata dla osób niepełnosprawnych. Doceniamy każdą formę wsparcia, ponieważ pozwala nam kontynuować naszą misję i dostarczać wartościową edukację osobom niewidomym na całym świecie."
                }
                
                
            }
    };
}
export default Translate_pl_PL;