import React, { Component } from 'react';
import BaseForm from '../../../spxbasecode/cmps/BaseForm';
import Translate from '../../../Translate';
import BaseInputString from '../../../spxbasecode/cmps/BaseInputString';
import BaseImage from '../../../spxbasecode/cmps/BaseImage';
import BaseButtonIcon from '../../../spxbasecode/cmps/BaseButtonIcon';
import Tools from '../../../spxbasecode/Tools';
class EduMagQuickGenSSCCCmp extends Component {
  constructor(props) {
    super(props);
    this.tag="EduMagQuickGenSSCCCmp";
    this.module="EduMag";
    
    this.state = {
      sscc:"",
      productId:0,
      productionDate:new Date().toISOString().slice(0, 10),
      validDate:Tools.addMountsToDate(new Date(),24).toISOString().slice(0, 10),
  //    error: null
    };
    this.state.productionDate=new Date().toISOString().slice(0, 10);
    //this.state.productionDate=new Date().toISOString().slice(0, 10);
    this.initFormAsync();
    this.form={vals:{login:"aaa"}};
  }
  async initFormAsync(){
    let this2=this;
    window.App.API.exec("sscc","getSSCC",{},(ss)=>{
      let dd=ss.data;
      this2.setState({ sscc: dd.id });
      
      
   },(ee)=>{
    console.log("XxxxXX3eeee",ee);
   });
   
  }
  handleChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }
  succ(e){
    let kk=this.parent;
    let zz=kk.form;
    zz.getData().then(function(dd){
      let params={htmlPrint:e.svg,sscc:dd.sscc,productId:dd.productId};
      window.App.changePage("EduMag","edumag_print_sscc", params);
    });
  }
  initForm(){
    let this2=this;

    setTimeout(()=>{
     // if (window.App.Config.isDev){
        this2.form.init(this2.state);
     // }
    },300);
  }
  handleSubmit = event => {
    event.preventDefault();
    
   
  }
  render() {
    let TT=Translate.getT(window.App.lang,this.module,this.tag);
    let hh=Tools.copy(this.labelSSCC);
  //  console.log(TT);
  //  return (<div></div>);
    return (
      <BaseForm lab1="Przygotuj etykietę" onSucc={this.succ} act="getLabel" module="sscc" className="row" parent={this}>
        <BaseInputString module={this.module} pageName={this.pageName} 
        name="sscc" id="sscc" label={TT.sscc.label} 
        type="string" readonly="true"
        placeholder={TT.sscc.placeholder} 
        parent={this} require="true" />
        <BaseInputString module={this.module} pageName={this.pageName} 
        name="productId" id="productId" label={TT.productId.label} 
        type="select" list="products"
        placeholder={TT.productId.placeholder} 
        parent={this} require="true" />
        <BaseInputString module={this.module} pageName={this.pageName} 
       type="date"
       name="productionDate" id="productionDate" label={TT.productionDate.label} 
        placeholder={TT.productionDate.placeholder} 
        parent={this} require="true"/>
         <BaseInputString module={this.module} pageName={this.pageName} 
       type="date"
       name="validDate" id="validDate" label={TT.validDate.label} 
        placeholder={TT.validDate.placeholder} 
        parent={this} require="true"/> 
      </BaseForm>
      
    );
  }
}
export default EduMagQuickGenSSCCCmp;