import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap';
import Tools from '../Tools.jsx';
import Validators from '../Validators.jsx';
import BaseError from './BaseError.jsx';
import Translate from '../../Translate.jsx';
class BaseInputString extends Component {
    constructor(props){
        super();
        this.isLoadData=false;
        this.isNeedLoad=false;
        this.props=props;
        this.list=[];
        this.isReadOnly=false;
        if(!Tools.empty(props.readonly)){
          this.isReadOnly=props.readonly;
        }
        this.module=props.module;
        this.pageName=props.pageName;
        this.value=null;
        if (this.props.type=="select"){
          this.isNeedLoad=true;
        }
        this.state={
          value:this.value
        };
        this.states={
          rq:1,
          id:props.id,
          className2:"inputStringNeutral",
          label:props.label,
          name:props.name,
          error:"",
          placeholder:props.placeholder,
          validator:{
            maxLength:props.maxlength,
            minLength:props.minlength,
            require:props.require
          }

        };
        this.validator=this.states.validator;
        let this2=this;
        setTimeout(()=>{

        this2.props.parent.form.addObj(this2);
        },500);
        //this.states.type="text";
    }
    setNeutral(){
      this.states.error=null;
      this.states.className2="inputStringNeutral";
      this.upd();      
    }
    setOK(){
      this.states.error=null;
      this.states.className2="inputStringOK";
      this.upd();
    }
    upd(){
      this.setState({ rq:this.states.rq++ });
    }

    setError(error){
      let TT=Translate.getT(window.App.lang,"Error","Error");
      let xx="inputStringError";   
      this.states.className2=xx;
      let str=TT[error];
      console.log(this.states.validator.minLength);
      let MAP={
        "label":this.states.label,
        "minLength":this.states.validator.minLength,
        "maxLength":this.states.validator.maxLength,
        
        
    };
      for(let k in MAP){
        //console.log(k);
        let val=MAP[k];
        str=str.replaceAll("$"+k+"$",val);
      }
      //console.log(str,TT[error]);
      this.states.error=str;
      this.upd();
  
    
    }
    async valid(){
      this.setNeutral();
      let val=this.getValue();
      let val1= await Validators.IsRequire(this,val);
      if (!val1){
        return false;
      }
      let val2= await Validators.HasMinLength(this,val);
      if (!val2){
        return false;
      }
      let val3= await Validators.HasMaxLength(this,val);
      if (!val3){
        return false;
      }
      let val4=true;
      if (["passwordNumber"].indexOf(this.props.type)>=0){
          val4=await Validators.IsNumeric(this,val);
      }
      if (!val4){
        console.log("EEEEEE",this.states.name,val);
        return false;
      }

      this.render();      
      return val1&&val2&&val3;
    }
    getValue(){
      if (this.props.type!="select"){
        return this.value;
      }
      {
        let inp = document.getElementById(this.states.id);
        this.value=inp.value;
        return this.value;   
      }
    }
    setValue(data){
      this.value=data;
      this.state.value=this.value;
     let inp = document.getElementById(this.states.id);
         if (inp!==null){
          inp.value=this.value;
         }
      
      this.upd();
    }
    handleChange = event => {
      this.setNeutral();
      const { name, value } = event.target;
      //console.log(value);
      this.value=Tools.copy(value,true);
      //this.states.value=Tools.copy(value,true);
      //value={this.state.value} 
    }
   renderString(typ){
    let className3=this.states.className2+"";
      if (this.isReadOnly){
        return (<Input className={className3} type={typ} 
          name={this.states.name} readOnly={true} id={this.states.id} 
          placeholder={this.states.placeholder}  
          onChange={this.handleChange} />);
      }else{
        return (<Input className={className3} type={typ} 
          name={this.states.name} id={this.states.id} 
          placeholder={this.states.placeholder}  
          onChange={this.handleChange} />);
      }
   }
   
   renderSelect(){
    let className3="form-select "+this.states.className2+"";   
      return (<select name={this.states.name} id={this.states.id} className={className3} size="3" aria-label="size 3 select example">
      {
                Object.keys(this.list).map(function(key) {
                  let el=this.list[key];
                  let vname=el.vname;
                  let id=el.id;
                  return <option key={id} value={id}>{vname}</option>
                }.bind(this))
              }
    </select>);
   }

  loadData(){
    //console.log("loadData",this.props);
    let listName=this.props.list;
    let this2=this;
    window.App.getList(listName,function(data){
      this2.isLoadData=true;
     // console.log("loadData data",data);
      this2.list=data;
      this2.upd();
    });
    
  }
  render() {
    if (this.isNeedLoad){
      if (!this.isLoadData){
        this.loadData();
        return (<div>Not load</div>);
      }
    }
    let rendObj=this.renderString("string");
    switch (this.props.type) {
      case "passwordNumber":rendObj = this.renderString("password");
        break;
        case "select":rendObj = this.renderSelect();
        break;
        case "date":rendObj = this.renderString("date");
        break;
      default:
        break;
    }
    
    /*
    <FormGroup>
    <Label style={{color:'white'}} for="email">{this.states.label}</Label>*/
    let label2="";
    let isShowLabel=true;
    if(isShowLabel){
      label2=(  <Label style={{color:'black'}} for="email">{this.states.label}</Label>
      );
    }
    return (
      <div className="row mb-3">
      {label2}
      {rendObj}
      <BaseError  text={this.states.error}></BaseError>
    </div>
           
    );
  }
}

export default BaseInputString;